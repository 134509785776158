<template>
  <div class="filmCard" :style="{ width: width }">
    <div class="filmCard-top">
      <div class="filmCard-img">
        <van-image
          width="100%"
          height="100%"
          radius="4"
          :src="filmImg"
          lazy-load
          @click="handleMovie"
        />
      </div>
      <div class="filmCard-info">
        <div class="filmCard-info-title">
          <span @click="handleMovie"
            >{{ filmName }} <span v-if="filmYear">({{ filmYear }})</span></span
          >
          <!-- <span><van-icon name="share-o" @click="shareGroup"/>&nbsp;&nbsp; </span> -->
        </div>
        <div class="filmCard-info-number">
          <span>已拼人数</span>
          <span>{{ filmNumber }}人</span>
        </div>
        <div class="filmCard-info-text">期待放映时间</div>
        <div class="filmCard-info-date">{{ filmDate }}</div>
        <div class="filmCard-info-t">
          <span>发起人｜</span>
          <span class="">{{ filmUser }}</span>
        </div>
        <div class="filmCard-info-t">
          <span>发起时间｜</span>
          <span class="">{{ filmTime }}</span>
        </div>
      </div>
    </div>
    <div class="filmCard-footer">
      <span class="filmCard-footer-address">
        {{ filmAddress }}
      </span>
      <span class="filmCard-footer-btn">
        <van-button
          type="info"
          size="mini"
          round
          plain
          style="padding: 10px"
          :disabled="isDisabled == 1 ? true : false"
          @click="joinGroup"
        >
          一起点
        </van-button>
      </span>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'

export default {
  name: 'comGroupCard',
  props: {
    width: {
      type: String,
      default: '94%',
    },
    cardBgColor: {
      type: String,
      default: '#000',
    },
    isDisabled: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: '',
    },
    // 影片id
    filmId: {
      type: String,
      default: '',
    },
    // 影片名称
    filmName: {
      type: String,
      default: '',
    },
    // 影片图片
    filmImg: {
      type: String,
      default: '',
    },
    // 参与人数
    filmNumber: {
      type: Number,
      default: 0,
    },
    // 放映时间
    filmDate: {
      type: String,
      default: '',
    },
    // 发起人
    filmUser: {
      type: String,
      default: '',
    },
    // 发起时间
    filmTime: {
      type: String,
      default: '',
    },
    // 放映地点
    filmAddress: {
      type: String,
      default: '',
    },
    // 影片年份
    filmYear: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      //
    }
  },
  methods: {
    //参加一起点
    joinGroup() {
      // this.$toast("参加一起点");
      this.$emit('addGroup', this.id)
    },
    // 分享一起点
    shareGroup() {
      // this.$toast("分享一起点");
      this.$emit('shareGroup', this.filmId)
    },
    // 点击影片
    handleMovie() {
      this.$emit('handleMovie', this.filmId)
    },
  },
}
</script>

<style scoped lang="less">
.filmCard {
  width: 94%;
  //height: 206px;
  background: #ffffff;
  border-radius: @border-radius-base;
  display: flex;
  flex-direction: column;
  vertical-align: center;
  margin: 0 auto 10px auto;
  //border:1px solid #000000;

  &-top {
    display: flex;
    flex-direction: row;
  }

  &-img {
    width: 88px;
    height: 132px;
    margin: 12px 12px 12px 10px;
  }

  &-info {
    width: 247px;
    height: 100%;
    text-align: left;
    margin-top: 12px;
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC, serif;
    font-weight: 600;
    color: #363a44;
    line-height: 24px;

    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-number {
      width: 124px;
      height: 19px;
      border-radius: 2px;
      border: 1px solid #f4ddd4;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c3b1a;
      line-height: 19px;
      display: flex;
      margin: 6px 0 2px 0;

      > span {
        flex: 1;
        text-align: center;

        &:first-child {
          width: 62px;
          height: 19px;
          background: rgba(228, 129, 118, 0.26);
          border-radius: 2px;
        }

        &:last-child {
          color: #c37067;
        }
      }
    }

    &-text {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
      line-height: 17px;
    }

    &-date {
      margin-top: 2px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
    }

    &-t {
      //height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
      line-height: 17px;
      margin-top: 6px;

      > span {
        &:last-child {
          color: #333333;
        }
      }
    }
  }

  &-footer {
    width: 94%;
    height: 40px;
    background-image: url('./../../assets/info/itembg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 0px 0px 4px 4px;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-address {
      height: 19px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC, sans-serif;
      color: #333333;
      line-height: 19px;
    }
  }
}
</style>
